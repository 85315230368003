import React, {useEffect, useState} from 'react';

const useGet = (url, options = {method: 'GET'}, dependecies = []) => {
  const [data, setData] = useState({
    error: false,
    message: '',
    loading: false,
    results: [],
  });

  const fetchData = async aurl => {
    if (aurl) {
      url = aurl;
    }

    setData({
      error: false,
      message: '',
      loading: true,
      results: [],
    });

    fetch(url, options)
      .then(req => req.json())
      .then(response => {
        setData({
          error: response.error,
          message: response.message,
          loading: false,
          results: response.data,
        });
      })
      .catch(error => {
        setData({
          error: true,
          message: 'An error occurred.',
          loading: false,
          results: [],
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, dependecies);

  return {
    ...data,
    fetchData,
  };
};

export default useGet;
