import React, { useContext, useState,useEffect } from 'react'
import { Dropdown, Form, Modal, Table } from 'react-bootstrap';
import { CustomButton, Footer, Header, ProgressItem, SmallScreenHeader } from '../../components';
import "./index.css";
import { CartContext, UserContext } from '../../contexts';
import { Auth } from '..';
import { useNavigate } from 'react-router-dom';
import { APIURL, APPDATAURLS, BASEURL } from '../../utils/APPADATAURLS';
import Lottie from 'lottie-react';
import { AiOutlineRollback } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from 'react-icons/fa';

import mpesalogo from "../../assets/logos/mpesa.png";
import visalogo from "../../assets/logos/visa.png";
import mastercardlogo from "../../assets/logos/mastercard.png";
import paypallogo from "../../assets/logos/paypal.png";

function CheckoutPage() {
  const [showModal,setShowModal] = useState(false);
  const [numCount,setNumCount] = useState(0);
  const [cartData,setCartData] = useContext(CartContext);
  const [userData,setUserData] = useContext(UserContext);
  const [selectedAddress,setSelectedAddress] = useState({
    index:null,
    id:null,
    address:null,

  });

  const [addresses,setAddresses] = useState({
    results:[],
    loading:false,
    error:false,
    message:""
  });

    const [credentials, setCredentials] = useState({
      phoneNumber: userData.data.phoneNumber??"",
      address:null,
    });

  const [result, setResult] = useState({
    data: {},
    loading: false,
    error: false,
    message: '',
  });

  const [activeTab,setActiveTab] = useState(0);

  const [selectedPaymentMethod,setSelectedPaymentMethod] = useState(null);

  const [openMore,setOpenMore] = useState(false);

  const [orderNow,setOrderNow] = useState(false);
  const navigate = useNavigate();
  const total = cartData.
          map((item)=>Number(item.quantity)*Number(item.productPrice)).
          reduce((prev,curr)=>prev+curr,0);
  
  const getAddresses = ()=>{
      setAddresses({
          results:[],
          loading:true,
          error:false,
          message:""
        });
      fetch(`${APPDATAURLS.customerAddresses}${userData.data.id}`,{
          method:"GET"
      }).
      then(req=>req.json()).
      then(response=>{
        
        setAddresses({
          results:response.data,
          loading:false,
          error:response.error,
          message:response.message
        });

        
      }).catch(e=>{
          setAddresses({
          results:[],
          loading:false,
          error:true,
          message:e.message
        })
      })
    }

  let requestCount = 0;
  const stkQuery = checkoutRequestID => {
    if (requestCount >= 20) { 
      setResult({
        data: {},
        loading: false,
        error: true,
        message: 'Waited for too long. Please try again.',
      });
      return;
    }
    requestCount++;

    let formData = new FormData();
    formData.append('query', checkoutRequestID);
    formData.append('checkoutRequestID', checkoutRequestID);
    fetch(APPDATAURLS.queryURL, {
      method: 'POST',
      body: formData,
    })
      .then(req => req.json())
      .then(response => {
        if(response.isCanceled){
          setResult({
            data: {},
            loading: false,
            error: response.error,
            message: response.message,
          });
          return;
        }

        if (response.error == true) {
          setTimeout(() => {
            stkQuery(checkoutRequestID);
          }, 2000);
        } else {
          setResult({
            data: response.data,
            loading: false,
            error: response.error,
            message: response.message,
          });
          setCartData([]);
          localStorage.removeItem("cartData");
          setTimeout(()=>{
              setShowModal(false);
              navigate(`/checkout/${response.data.orderID}`);
          },2000);
        }
       
      })
      .catch(e => {
        setTimeout(() => {
          stkQuery(checkoutRequestID);
        }, 2000);
       
      });
  };

  const placeOrder = () => {
    //console.log(selectedAddress);
    if(selectedAddress.address == null){
                  setResult({
                    loading:false,
                    data:[],
                    error:true,
                    message:"Select an address first"
                  })
                  setNumCount(0);
                  setShowModal(true);
                  return;
    }
    setResult({data: {}, loading: true, error: false, message: ''});
    let formData = new FormData();
    
    formData.append('place-order', 'place-order');
    formData.append(
      'phoneNumber',
      credentials.phoneNumber == ''
        ? userData.data.phoneNumber
        : credentials.phoneNumber,
    );

    formData.append("orderItems",JSON.stringify(cartData));
    formData.append("userID",userData.data.id);
    formData.append("addressID",addresses.results[selectedAddress.index].addressID)
    fetch(APIURL, {
      method: 'POST',
      body: formData,
    })
      .then(req => req.json())
      .then(response => {
        if (response.error == false) {
          setResult({
            data: {},
            loading: false,
            error: response.error,
            message: response.message,
          });
          stkQuery(response.data.CheckoutRequestID);
        } else {
          setResult({
            data: {},
            loading: false,
            error: response.error,
            message: response.message,
          });
        }
      })
      .catch(e => {
        setResult({
          data: {},
          loading: false,
          error: true,
          message: 'An error occurred,try again later.',
        });
        
      });
  };

  const [cost,setCost] = useState(null);
  const [costError,setCostError] = useState({
    state:false,
    isShown:false,
    message:""
  });
  const deliveryCost = (addressID)=>{
    setCostError({
      state:false,
      isShown:false,
      message:"",
    });
    let formData = new FormData();
    formData.append("delivery-cost","");
    formData.append("items",JSON.stringify(cartData));
    formData.append("userID",userData.data.id);
    formData.append("addressID",addressID);
    fetch(APIURL,{
      method:"POST",
      body:formData
    }).
    then(req=>req.json()).
    then(res=>{
      
      setCost(res?.data?.cost);

      setCostError({
        state:res?.error,
        isShown:res?.error,
        message:res?.message,
      });

    }).catch(e=>{
    
      setCostError({
        state:true,
        isShown:true,
        message:"Unable to retrieve delivery cost. Try again later.",
      });
    })
  }

  useEffect(()=>{
    getAddresses();
    window.scroll(0,0);
  },[userData.loggedIn]);

  useEffect(()=>{
    window.scrollTo(0,0);
  },[activeTab]);
  return (
    <>
    <Modal
    show={costError.isShown}
    onHide={()=>{
      setCostError({...costError,isShown:false,message:""})
    }}

    centered={true}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
          <p className={`text text-${costError.state?"danger":"success"}`}>
            {costError.message}
          </p>
      </Modal.Body>
    </Modal>

    {
      userData.loggedIn?
      <div
        className='checkout-body'
    >
      <Header/>
      <SmallScreenHeader title={"Checkout"} />
      <div
        className='checkout-wrapper'
      >

        <div
        className='navigation'
        >
          <ProgressItem title={"Cart info"} isCompleted={cartData.length>0}/>
          <ProgressItem title={"Address"} isCompleted={selectedAddress.address?true:false} />
          <ProgressItem title={"Payment"} isCompleted={selectedPaymentMethod?true:false} />
          {/* <ProgressItem title={"Finalize"} isCompleted={selectedPaymentMethod?true:false}/> */}
          
        </div>
  
        <div
          className='checkout-holder'

        >
          <div className='div1'>
            {
              activeTab==0?
          <CartInfo cost={cost} total={total} />
          :<></>
            }
          {activeTab==1?
          <SelectAddress 
            getAddresses={getAddresses} 
            addresses={addresses}
            openMore={openMore}
            setOpenMore={setOpenMore}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            deliveryCost={deliveryCost}
          />:<></>}
          {activeTab==2?
          <SelectPaymentMethod
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          />:<></>
            }
          {activeTab==3?
          <MpesaPayment
            cost={cost}
            total={total}
            orderNow={orderNow}
            setOrderNow={setOrderNow}
            setShowModal={setShowModal}
            setResult={setResult}
            setCredentials={setCredentials}
            credentials={credentials}
            placeOrder={placeOrder}
            selectedAddress={selectedAddress}
            setNumCount={setNumCount}
          />:<></>
            }

          
          <Summary selectedAddress={selectedAddress} total={total} cost={cost} selectedPaymentMethod={selectedPaymentMethod}/>

          </div> 
          <div 
          className='btns1'
          style={{
            width:"100%",
            gap:10,
            marginBottom:10
          }} >
            <CustomButton 
            title={"Previous"}
            backgroundColor={"var(--app-green)"}
            pressAction={()=>setActiveTab(activeTab-1)} 
            disabled={activeTab==0}
            accessoryLeft={<FaChevronLeft size={15} />}
            />
           
            <CustomButton 
            title={activeTab==0?"Select address":activeTab==1?"Payment method":"Order now"}
            backgroundColor={"var(--app-green)"}
            pressAction={()=>setActiveTab(activeTab+1)} 
            disabled={activeTab==3 || costError.state}
            accessoryRight={<FaChevronRight size={15}/>}
            />
          </div>
            <Modal
            show={showModal}
            onHide={()=>setShowModal(false)}
            centered={numCount==0?false:true}
            >
              <Modal.Header
                closeButton
              >
                <Modal.Title
                className={result.error?"text-danger":'text-success'}
                style={{
                  display:"flex",
                  flexDirection:"row",
                  gap:10
                }}
                >
                  <img src='/assets/images/savo_logo.jpg' style={{
                    width:40,
                    height:40,
                    objectFit:"cover",
                    borderRadius:5
                  }} />
                  {
                    result.error?"Error":result.loading?"Loading. Please wait...":""
                  }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body >
                {
                  numCount==0?
                  <p className='text-danger' >
                    {result.message}
                  </p>:
                  <>
                  {
                    result.error?
                    <div
                      style={{
                        width:"100%",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        justifyContent:"center",
                        gap:10
                      }}
                    >
                      <Lottie 
                      style={{
                        width:40,
                        height:40
                      }}
                      loop={false} 
                      animationData={require("../../assets/lottie_animations/wrong1.json")}/>
                      <p>{result.message}</p>
                      
                    </div>:
                    result.loading?
                    <Lottie 
                    size={100}
                    loop 
                    animationData={require("../../assets/lottie_animations/loading3.json")}/>
                    :<div
                      style={{
                        width:"100%",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        justifyContent:"center",
                        gap:10
                      }}
                    >
                      <Lottie 
                      style={{
                        width:40,
                        height:40
                      }}
                      loop={false} 
                      animationData={ result.message=="Request cancelled"?
                        require("../../assets/lottie_animations/wrong1.json"):
                        require("../../assets/lottie_animations/ok.json")}/>
                      <p
                        className={result.message=="Request cancelled"?"text-danger":"text-success"}
                      >{result.message}</p>
                    </div>
                  }
                  
                  </>

                }
              </Modal.Body>
            </Modal>

          

        </div>
        
      </div>
      <div className='btns2' >
      <CustomButton 
            title={"Previous"}
            backgroundColor={"var(--app-green)"}
            pressAction={()=>setActiveTab(activeTab-1)} 
            disabled={activeTab==0?true:false}
            accessoryLeft={<FaChevronLeft size={15} />}
            />

            <CustomButton 
            title={activeTab==0?"Select address":activeTab==1?"Payment method":"Order now"}
            backgroundColor={"var(--app-green)"}
            pressAction={()=>setActiveTab(activeTab+1)} 
            disabled={activeTab==3?true:false}
            accessoryRight={<FaChevronRight size={15}/>}
            />
            
      </div>
      <Footer/>
    </div>
      :
      <Auth.LoginPage/>
    }
    </>
  )
}


const SelectAddress = ({getAddresses,addresses,openMore,setOpenMore,selectedAddress,setSelectedAddress,deliveryCost})=>{
  const navigate = useNavigate();
  return (
    <div
            style={{
              width:"100%",
              position:"relative",
              height:openMore?"auto":280,
              overflowY:openMore?"scroll":"hidden",
              transition:"height .5s ease-in-out",
            }}
            className='select-address'
          >

            <p><b>Select an Address</b></p>
            <div
          style={{
            marginBottom:40,
            width:"100%"
          }}
          >
          <CustomButton
            title={"Add new address"}
            backgroundColor={"var(--app-green)"}
            color={"white"}
            pressAction={()=>{
              navigate("/profile/add-address")
            }}
          />
          </div>
              {
                addresses.error?<div
                style={{
                  width:"100%",
                  display:"flex",
                  flexDirection:"row",
                  alignItems:"center",
                  justifyContent:'space-between',
                  paddingRight:10
                }}
                >
                  Error fetching addresses 
                <AiOutlineRollback 
                size={25} 
                onClick={getAddresses}
                style={{
                  color:"var(--app-color)"
                }} /> </div>:
                addresses.loading?<option>Loading addresses...</option>:
                addresses.results.length>0?
                <div style={{
                  
                }}>
                  {
                  addresses.results.map((address,index)=>{
                  return(
                    <>
                      {
                        address.latitude?
                        <div
                        className='address-in-checkout'
                        key={index}  
                        onClick={(e)=>{
                            setSelectedAddress({...selectedAddress,index,address:addresses.results[index]});
                            deliveryCost(addresses.results[index].addressID);
                          }}
                        >
                          <label
                          style={{
                            marginBottom:10,
                            display:"flex",
                            flexDirection:"row",
                            alignItems:"center",
                            gap:10
                          }}
                          > <FiMapPin/> 
                          {
                            address.google_data?
                            address.google_data?.results[0]?.formatted_address:
                            "Pinned locaton"
                          }
                          </label>
                          <CustomButton
                          title={"View on map"}
                          backgroundColor={"var(--app-green)"}
                          pressAction={()=>navigate(`/location/${address.latitude}/${address.longitude}`)}
                          />

                          <div
                          className='selected-displayer'
                          style={{
                            display:"flex",
                            position:"absolute",
                            right:5,
                            top:5,
                            width:20,
                            height:20,
                            borderRadius:10,
                            
                            backgroundColor:index==selectedAddress.index?"var(--app-color)":"#ffffff",
                          }}
                          />
                        </div>:
                        <div 
                    className='address-in-checkout'
                    key={index}  
                    onClick={(e)=>{
                      setSelectedAddress({...selectedAddress,index,address:addresses.results[index]});
                      deliveryCost(addresses.results[index].addressID);
                    }}
                    
                    >
                      <label>{address.countyName}</label>
                      <label>{address.cityName}, {address.locationName} area</label>
                      {address.description==null?
                        <></>:
                        address.description.length>40?
                        <label
                        style={{
                          fontWeight:"600",
                          fontSize:13,
                          color:"#919191"
                        }}
                        >{address.description.substring(0,40)}...</label>:
                        <label
                        style={{
                          fontWeight:"600",
                          fontSize:13,
                          color:"#919191"
                        }}
                        >{address.description}</label>}
                      
                      <div
                      className='selected-displayer'
                      style={{
                        display:"flex",
                        position:"absolute",
                        right:5,
                        top:5,
                        width:20,
                        height:20,
                        borderRadius:10,
                        backgroundColor:index==selectedAddress.index?"var(--app-color)":"#ffffff"
                      }}
                      />
                    </div>
                      }
                    </>
                  )
                })}
                </div>:<option>No addresses</option>
              }
          <br/>
          
          
          <div
          style={{
            marginBottom:50
          }}
          />
          {
            addresses.results.length>2?
            <div
          onClick={()=>setOpenMore(!openMore)}
          style={{
            width:"100%",
            height:40,
            display:"flex",
            flexDirection:"row",
            alignItems:"center",
            justifyContent:"space-between",
            backgroundColor:"#ffffff",
            borderRadius:5,
            padding:2,
            position:"absolute",
            bottom:0,
            boxShadow:"4px 4px 4px #f5f5f"
          }}
          >
            <label>{openMore?"Close":"View more"}</label>
            {
              !openMore?
              <FaChevronDown/>:
              <FaChevronUp/>
            }
          </div>:
          <></>
          }
          
          </div>
  )
}


const CartInfo = ({cost,total})=>{
  const [cartData,setCartData] = useContext(CartContext);
  const [userData,setUserDauserData] = useContext(UserContext);
  return (
    <div
          className='order-data'
          style={{
              width:"100%"
            }}
          >
            <p><b>Order Items</b></p>
            <Table responsive striped={false} >
              <thead>
                <th 
                >#</th>
                <th 
                >Item</th>
                <th 
                >Quantity</th>
                <th 
                >Price</th>
                <th 
                >Total (Ksh.)</th>
              </thead>
              <tbody>
                {
                  cartData.map((item,index)=>{
                    return<tr key={index} >
                      <td
                      >{index+1}</td>
                      <td
    
                      >{item.productName}</td>
                      <td
    
                      >{item.quantity}</td>
                      <td
    
                      >{item.productPrice}</td>
                       <td
      
                       >{Number(item.quantity)*Number(item.productPrice)}</td>
                      </tr>
                  })
                }
                {cost && <tr>
                  <td style={{fontWeight: "bolder",textAlign:"right"}} colspan="4" >Shipment fee</td>
                  <td>{cost}</td>
                </tr>}
                <tr>
                  <td style={{fontWeight: "bolder",textAlign:"right"}} colspan="4" >Total</td>
                  <td>{cost?(total + cost):total}</td>
                </tr>
              </tbody>
            </Table>
            <div>

            </div>
            
          </div>
  )
}

const payments = [
  {
    name:"M-Pesa",
    info:"Pay now",
    alias:"mpesa",
    image:mpesalogo,
  },
  {
    name:"Visa",
    info:"Coming soon...",
    alias:"visa",
    image:visalogo,
  },
  {
    name:"Mastercard",
    info:"Coming soon...",
    alias:"mastercard",
    image:mastercardlogo,
  },
  {
    name:"Paypal",
    info:"Coming soon...",
    alias:"paypal",
    image:paypallogo,
  },
]

const SelectPaymentMethod = ({selectedPaymentMethod,setSelectedPaymentMethod})=>{
  
  return(
    <div
    style={{
      width:"100%"
    }}
    >
      <h1>Select payment method</h1>
      <div className='select-payment' >
        {
          payments.map((payment,index)=>{
            return(
              <div
              key={index}
              onClick={()=>{
                if(payment.name == "M-Pesa"){
                  setSelectedPaymentMethod(payment.name);
                }
                
              }}
              >
                <img
              src={payment.image}
              />
              <section>
                <p>{payment.name}</p>
                <label>{payment.info}</label>
              </section>
              <div
                                className='selected-displayer'
                                style={{
                                  display:"flex",
                                  position:"absolute",
                                  right:5,
                                  top:5,
                                  width:20,
                                  height:20,
                                  borderRadius:10,
                                  backgroundColor:selectedPaymentMethod==payment.name?"var(--app-color)":"#f5f5f5",
                                }}
                                />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const MpesaPayment = ({total,cost,orderNow,setOrderNow,setShowModal,setResult,setCredentials,credentials,placeOrder,selectedAddress,setNumCount})=>{
  const [userData,setUserDauserData] = useContext(UserContext);
  return(
    <>
              <div className='order-now' >
                <p><b>Payment</b></p>

                <label style={{
                  fontWeight:"400"
                }} >Enter M-PESA phone number, then click Confirm. 
                  An stk push will be sent to your phone.
                  (This defaults to phone number registered with.)</label>
                <input 
                  placeholder={userData.data.phoneNumber?userData.data.phoneNumber:"07xxxxxxxx"}
                  onChange={(e)=>setCredentials({...credentials,phoneNumber:e.target.value})}
                  />
                <div className='order-now-buttons' >
                  {/* <CustomButton
                    title={"Cancel"}
                    backgroundColor={"var(--app-green)"}
                    color={"white"}
                    pressAction={()=>{
                      setOrderNow(false);
                    }}
                    /> */}

                    <CustomButton
                      title={`Confirm Ksh.${!isNaN(total+cost)?total+cost:0}`}
                      backgroundColor={"var(--app-green)"}
                      color={"white"}
                      disabled={isNaN(total+cost)?true:false}
                      pressAction={()=>{
                        placeOrder();
                        setNumCount(1);
                        setShowModal(true);
                      }}
                      />
                </div>
              </div>

              {/*
              
              <CustomButton
              title={"Order now"}
              backgroundColor={"var(--app-green)"}
              color={"white"}
              pressAction={()=>{
                if(selectedAddress.address == null){
                  setResult({
                    loading:false,
                    data:[],
                    error:true,
                    message:"Select an address first"
                  })
                  setNumCount(0);
                  setShowModal(true);
                }else{
                  setNumCount(1);
                  setOrderNow(true);
                }
              }}
              />
              
              
              
              */}
              
            
    </>
  )

}

const Summary = ({selectedAddress, total,cost,selectedPaymentMethod})=>{
  const navigate = useNavigate();
  return(
    <div
                    className='total-and-buttons'
                    
                    >
                        <h4>Summary</h4>

                        {
                          selectedAddress.address?
                          <label>Deliverying to: {selectedAddress.address.latitude?"Pinned location":selectedAddress.address.locationName}</label>:
                          <></>
                        }
                        {
                          selectedPaymentMethod?
                          <label>Payment method: {selectedPaymentMethod}</label>:
                          <></>
                        }
                        {
                          total>0?
                          <label>Sub total : Ksh.{total}</label>:
                          <></>
                        }
                        {
                          cost>0?
                          <label>Shipment fee : Ksh.{cost}</label>:
                          <></>
                        }
                        <label>
                            Total : Ksh.{total+cost}
                        </label>
                        <div
                        style={{
                            right:0,
                            display:"flex",
                            flexDirection:"column",
                            gap:10,
                            justifyContent:"space-between",
                            width:"100%",
                            padding:5,
                        }}
                        >
                            <CustomButton
                            title={"Continue shopping"}
                            width={"100%"}
                            pressAction={()=>{navigate("/")}}
                            backgroundColor={"#225ea3"}
                            />

                            {/* <CustomButton
                            title={"Checkout"}
                            width={"100%"}
                            pressAction={()=>{navigate("/checkout")}}
                            backgroundColor={"var(--app-green)"}
                            /> */}
                        </div>

                        
                    </div>
  );
}

export default CheckoutPage;