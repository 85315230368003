import React, { useState, useEffect, useContext } from "react";

import "./index.css";
import { APPDATAURLS } from "../../utils/APPADATAURLS";
import { FavoritesContext, UserContext } from "../../contexts";
import { useCookies } from "react-cookie";
import {
  Advert,
  Banner,
  BottomTabs,
  CategoriesBanner,
  CustomModal,
  FloatingComponents,
  Footer,
  Header,
  HeaderBanner,
  HeaderHero,
  ProductsBanner,
  SideBar,
} from "../../components";

/*
import {lazy} from "react";
const Advert =   lazy(()=>import("../../components/Advert/index"));
const Banner =   lazy(()=>import("../../components/Banner/index"));
const BottomTabs =   lazy(()=>import("../../components/BottomTabs/index"));
const Footer =   lazy(()=>import("../../components/Footer/index"));
const Header =   lazy(()=>import("../../components/Header/index"));
const HeaderHero =   lazy(()=>import("../../components/HeaderHero/index"));
const ProductsBanner =   lazy(()=>import("../../components/ProductsBanner/index"));
const SideBar =   lazy(()=>import("../../components/SideBar/index"));*/

const HomePage = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [screenSize, sescreenSize] = useState(window.innerWidth);
  const [cookies, setCookies] = useCookies(["user"]);
  //console.log(cookies);
  const [userData, setUserData] = useContext(UserContext);
  const [favorites, setFavorites] = useContext(FavoritesContext);
  const fetchFavorites = () => {
    fetch(`${APPDATAURLS.userFavorites}${userData.data.id}`, {
      method: "GET",
    })
      .then((req) => req.json())
      .then((response) => {
        setFavorites(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userData.loggedIn) {
      fetchFavorites();
    }
  }, [userData]);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 800) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    });

    if (screenSize < 800) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [screenSize]);

  

  return (
    <div className="body">
      <Header />
      {/* <CustomModal style={{
                backgroundColor:"red"
            }}/> */}
      <div className="body-wrapper">
        <Advert />
        <div className="sidebar_and_banner">
          <div
            style={{
              width: "fit-content",
              display: isSmallScreen ? "none" : "flex",
            }}
          >
            <SideBar />
          </div>

          <Banner />
        </div>
        <div >
          <div
            style={{
              width: "fit-content",
              display: !isSmallScreen ? "none" : "flex",
            }}
          >
            <CategoriesBanner/>
          </div>
        </div>

        <HeaderBanner />

        <HeaderHero
          heading={"New"}
          toUrl={"/products"}
          subTitle={"Never seen before"}
        />

        <div>
          <ProductsBanner url={`${APPDATAURLS.random}&userID=`} />
        </div>
      </div>
      <BottomTabs />

      {/*<div
            style={{
                position:"fixed",
                bottom:10,
                backgroundColor:"transparent",
                height:"fit-content",
                zIndex:100,
                display:"flex",
                flexDirection:"row",
                right:50
            }}
        >
            <div/>
            <FloatingComponents.RightFloaters
                displayAble={true}
            />
        </div>*/}

      <Footer />
    </div>
  );
};

export default HomePage;
