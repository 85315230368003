import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../contexts/cartContext";
import {
  Addresses,
  BottomTabs,
  CustomButton,
  Footer,
  Header,
  HeaderHero,
  HorizontalProductsBanner,
  ProductsBanner,
} from "../../components";
import "./index.css";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { APPDATAURLS, BASEURL } from "../../utils/APPADATAURLS";
import Lottie from "lottie-react";
import { UserContext } from "../../contexts";
import Pay from "../../components/Pay";
import { FiTrash, FiTrash2, FiX } from "react-icons/fi";
import { Button, Modal } from "react-bootstrap";

function CartPage() {
  const navigate = useNavigate();
  const [cartData, setCartData] = useContext(CartContext);
  const [userData, setUserData] = useContext(UserContext);
  const [isBigScreen, setIsBigScreen] = useState(true);
  const [info, setInfo] = useState({
    message: "",
    isVisisble: false,
  });

  const [acceptDeletion, setAcceptDeletion] = useState(false);

  const [credentials, setCredentials] = useState({
    phoneNumber: userData.data.phoneNumber,
    address: null,
  });

  const [imageLoaded, setImageLoaded] = useState(false);

  const total = cartData
    .map((item) => Number(item.quantity) * Number(item.productPrice))
    .reduce((prev, curr) => prev + curr, 0);

  const removeFromCart = (itemID) => {
    let data = [...cartData];
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == itemID) {
        data.splice(i, 1);
        setCartData(data);
        localStorage.setItem("cartData", JSON.stringify(data));
        return;
      }
    }
  };

  const increaseQuantity = (itemID) => {
    let data = [...cartData];
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == itemID) {
        data[i].quantity += 1;
        setCartData(data);
        localStorage.setItem("cartData", JSON.stringify(data));
        return;
      }
    }
  };

  const decreaseQuantity = (itemID) => {
    let data = [...cartData];
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == itemID) {
        data[i].quantity -= 1;
        if (data[i].quantity == 0) {
          // setRemoveItem({
          //     state:true,
          //     itemID
          // })
          removeFromCart(data[i].id);
          return;
        }
        setCartData(data);
        localStorage.setItem("cartData", JSON.stringify(data));
        return;
      }
    }
  };

  const [removeItem, setRemoveItem] = useState({
    state: false,
    itemID: null,
  });

  useEffect(() => {
    window.scroll(0, 0);
    window.addEventListener("resize", () => {
      if (window.innerWidth < 800) {
        return setIsBigScreen(false);
      } else {
        return setIsBigScreen(true);
      }
    });

    if (window.innerWidth < 800) {
      return setIsBigScreen(false);
    } else {
      return setIsBigScreen(true);
    }
  }, [window.innerWidth]);

  const [imagesLoaded, serImagesLoaded] = useState([]);
  useEffect(() => {
    if (cartData?.length > 0) {
      cartData.map(() => {
        setImageLoaded(prev=>[...imagesLoaded, false]);
      });
    }
  }, []);
  return (
    <div className="body">
      <Modal show={removeItem.state} centered>
        <Modal.Body>
          <p>Do you want to remove this product from cart?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              setRemoveItem({ ...removeItem, state: false });
            }}
          >
            No
          </Button>
          <Button
            variant="success"
            onClick={() => {
              removeFromCart(removeItem?.itemID);
              setRemoveItem({ ...removeItem, state: false });
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Header />

      <div
        style={{
          marginTop: isBigScreen ? 80 : 0,
        }}
        className="cart-wrapper"
      >
        <div className="cart">
          {cartData.length > 0 ? (
            <div className="cart-data">
              <div className="items">
                <h3 className="title">My Cart</h3>
                {cartData.map((item, index) => {
                  return (
                    <Link
                      to={`/products/${item.productName.split("/").join(".")}/${
                        item.id
                      }`}
                      key={index}
                      className="cart-item"
                    >
                      <img
                        style={{
                          display: imagesLoaded[index] ? "flex" : "none",
                        }}
                        src={
                          imagesLoaded[index]
                            ? `${BASEURL}/${item.productImage}`
                            : "savo_logo.jpg"
                        }
                        className="cart-item-image"
                        onLoad={(e) => {
                          let output = imagesLoaded;
                          output[index] = true;
                          setImageLoaded(output);
                        }}
                        onError={(e) => {
                          let output = imagesLoaded;
                          output[index] = false;
                          setImageLoaded(output);
                        }}
                      />
                      {
                        console.log(imagesLoaded)
                        
                      }

                      <img
                        style={{
                          display: imagesLoaded[index] ? "none" : "flex",
                        }}
                        src={`/assets/images/savo_logo.jpg`}
                        className="cart-item-image-default"
                      />
                      
                      <div className="cart-item-info">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Link
                            to={`/products/${item.productName}/${item.id}`}
                            style={{
                              width: "100%",
                              textDecoration: "none",
                              color: "#000000",
                            }}
                          >
                            {isBigScreen
                              ? item.productName
                              : `${
                                  item.productName.length > 30
                                    ? `${item.productName.substring(0, 30)}...`
                                    : item.productName
                                }`}
                          </Link>
                          <label>Price: Ksh.{item.productPrice}</label>

                          <label>
                            Total: Ksh.{item.productPrice * item.quantity}
                          </label>
                        </div>

                        <div
                          className="cart-item-info-quantity"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Button
                            onClick={() => {
                              decreaseQuantity(item.id);
                            }}
                            style={{
                              backgroundColor: "var(--app-color)",
                              border: "none",
                              height: 40,
                              width: 40,
                            }}
                          >
                            <FaMinus color="#fff" />
                          </Button>

                          {item.quantity}

                          <Button
                            onClick={() => {
                              increaseQuantity(item.id);
                            }}
                            style={{
                              backgroundColor: "var(--app-color)",
                              border: "none",
                              height: 40,
                              width: 40,
                            }}
                          >
                            <FaPlus width={20} color="#fff" />
                          </Button>
                        </div>

                        {/*<CustomButton
                                        title={"Remove"}
                                        backgroundColor={"red"}
                                        width={"30%"}
                                            pressAction={()=>{
                                                removeFromCart(item.itemID);
                                                console.log("here")
                                                
                                            }}
                                        />*/}

                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // removeFromCart(item.id);
                            setRemoveItem({
                              state: true,
                              itemID: item.id,
                            });
                          }}
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 20,
                            position: "absolute",
                            right: 2,
                            top: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#ffffff",
                            /*boxShadow:"1px 2px 1px grey" */
                          }}
                        >
                          <FiTrash2
                            size={20}
                            fill="var(--app-green)"
                            color="var(--app-green)"
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>

              <div className="total-and-buttons">
                <h4>Summary</h4>
                <label>Total : Ksh.{total}</label>
                <div
                  style={{
                    right: 0,
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 5,
                  }}
                >
                  <CustomButton
                    title={"Continue shopping"}
                    width={"100%"}
                    pressAction={() => {
                      navigate("/");
                    }}
                    backgroundColor={"#225ea3"}
                  />

                  <CustomButton
                    title={"Checkout"}
                    width={"100%"}
                    pressAction={() => {
                      navigate("/checkout");
                    }}
                    backgroundColor={"var(--app-color)"}
                  />
                </div>
              </div>

              {/*<Addresses/>
                    <Pay/>*/}
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "fit-content",
                }}
              >
                <label
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "#919191",
                  }}
                >
                  Their is nothing in your cart
                </label>
                <Lottie
                  loop
                  animationData={require("../../assets/lottie_animations/empty_bag.json")}
                />
                <br></br>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <CustomButton
                    title={"Add Items"}
                    backgroundColor={"var(--app-color)"}
                  />
                </Link>
              </div>
            </div>
          )}
        </div>
        <HeaderHero heading={"Check also"} viewAll={false} />
        <HorizontalProductsBanner />
        <HeaderHero heading={"Featured"} viewAll={true} toUrl={"/products"} />
        <ProductsBanner url={APPDATAURLS.random} />
      </div>
      <BottomTabs />
      <Footer />
    </div>
  );
}

export default CartPage;
