import React, { useContext, useEffect } from "react";
import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIURL, APPDATAURLS, BASEURL } from "../../utils/APPADATAURLS";
import { Card, Modal, Toast } from "react-bootstrap";
import { useState } from "react";
import {
  BottomTabs,
  CustomButton,
  Footer,
  Header,
  HeaderHero,
  Loading,
  RateProduct,
  SmallScreenHeader,
} from "../../components";
import {
  FaBookmark,
  FaChevronDown,
  FaDiceOne,
  FaMapPin,
  FaMarkdown,
} from "react-icons/fa";
import { FiMapPin, FiX } from "react-icons/fi";
import { AiOutlineDown, AiOutlinePhone, AiOutlineUp } from "react-icons/ai";
import { useFetchData } from "../../hooks";
import { UserContext } from "../../contexts";
import { logo } from "../../assets";

const OrderDetails = ({ refresh, setRefresh }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalShown, setModalShown] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [userData, setUserData] = useContext(UserContext);

  const [showDetails, setShowDetails] = useState({
    payment: false,
    items: false,
  });

  const [received, setReceived] = useState({
    loading: false,
    error: false,
    message: "",
    results: null,
  });
  const markAsReceived = () => {
    setReceived({
      loading: true,
      error: false,
      message: "",
      results: null,
    });

    let formData = new FormData();
    formData.append("mark-as-received", "mark");
    formData.append("orderID", results?.id);

    fetch(APIURL, {
      method: "POST",
      body: formData,
    })
      .then((req) => req.json())
      .then((response) => {
        setReceived({
          loading: false,
          error: response.error,
          message: response.message,
          results: response.data,
        });
        //setRefresh(!refresh);
      })
      .catch((err) => {
        console.log(err);
        setReceived({
          loading: false,
          error: true,
          message: "An error occurred! Try again later",
          results: null,
        });
      });
  };

  const { results, loading, error, message,getData } = useFetchData(
    `${APPDATAURLS.userOrder}&userID=${userData.data?.id}&orderID=${id}`
  );

  const [imageModalOpen,setImageModalOpen] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, [results]);

  useEffect(() => {
    
    if(!received.loading && received.results?.state){
      getData();
    }
  }, [received.results]);

  return (
    <div className="body">
      <Modal 
        show={imageModalOpen}
        onHide={()=>setImageModalOpen(false)}
        onBackdropClick={()=>setImageModalOpen(false)}
      >
        <Modal.Header closeButton > {results?.delivery?.name}</Modal.Header>
        <Modal.Body
        style={{
          height:500,
          width:"100%"
        }}
        >
          <img
          src={results?.delivery?.image?
            `${BASEURL}/${results?.delivery?.image}`:
            logo
          }
          style={{
            width:"100%",
            height:"100%",
            objectFit:"contain"
          }}
          />
        </Modal.Body>
      </Modal>

      <Header />
      <SmallScreenHeader title={`Order : ${results?.orderNumber ?? ""}`} />
      <div className="body-wrapper">
        <div
          className="order-details"
          style={{
            position: "relative",
            transition: "height .5 ease-in-out",
            height: "auto",
          }}
        >
          {error ? (
            <div>{message}</div>
          ) : loading ? (
            <Loading.ProductLoading />
          ) : (
            <>
              <Toast
                show={received.error}
                onClose={() => {
                  setReceived({ ...received, error: false, message: "" });
                }}
                style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  zIndex: 300,
                  backgroundColor: "red",
                }}
              >
                <Toast.Header closeButton />
                <Toast.Body>{received.message}</Toast.Body>
              </Toast>

              <div
                style={{
                  position: "absolute",
                  right: 5,
                  top: 5,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                  color: results?.isDelivered ? "green" : "gray",
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                {results?.isDelivered ? "Delivered" : "Pending"}
                {results?.status == "Canceled" ? (
                  <FiX />
                ) : results?.status == "Pending" ? (
                  <FaBookmark />
                ) : (
                  <FaDiceOne />
                )}
              </div>
              <Modal
                show={modalShown}
                centered
                onHide={() => setModalShown(false)}
                onBackdropClick={() => setModalShown(false)}
              >
                <Modal.Header closeButton>
                  Rate {results?.data[productIndex].productName}
                </Modal.Header>
                <Modal.Body>
                  <RateProduct
                    product={results?.data[productIndex]}
                    setModalShown={setModalShown}
                  />
                </Modal.Body>
              </Modal>

              <Card className="payment">
                <label className="title">Order : {results?.orderNumber}</label>
                <label>Status: {results?.status}</label>

                <label>No. of items: {results?.data.length} items</label>
                <label>Payment : {results?.isPaid ? "Paid" : "Not paid"}</label>
              </Card>

              <Card className="payment">
                <label className="title">Delivery details</label>
                <img
                onClick={()=>setImageModalOpen(true)}
                style={{
                  width:100,
                  height:100,
                  borderRadius:50,
                  objectFit:"cover"
                }}
                src={results?.delivery.image?
                  `${BASEURL}/${results?.delivery?.image}`:
                  logo
                } alt={results?.delivery.name??"Delivery person"}/>
                <label>Name: {results?.delivery.name}</label>
                <label>
                  Transport means: {results?.delivery.transport_means}
                </label>
                <label>
                  Phone: {results?.delivery.phoneNumber}{" "}
                  <a href={`tel://${results?.delivery.phoneNumber}`}>
                    <AiOutlinePhone />
                  </a>
                </label>
                <label>Status : {results?.status}</label>
              </Card>

              <Card className="payment">
                <label className="title">Deliverying to</label>
                <label>
                  <FiMapPin style={{ marginRight: 10 }} />
                  {results?.location.google_data
                    ? results?.location.google_data.results[0].formatted_address
                    : `${results?.location.cityName}, ${results?.location.locationName}`}
                </label>

                {results?.location.google_data ? (
                  <CustomButton
                    title={"View on map"}
                    backgroundColor={"var(--app-green)"}
                    pressAction={() =>
                      navigate(
                        `/location/${results?.location.latitude}/${results?.location.longitude}/${"My products delivery location"}`
                      )
                    }
                  />
                ) : (
                  <></>
                )}
              </Card>

              {results?.isCanceled == 1 ? (
                <></>
              ) : (
                <>
                  <Card
                    className="payment"
                    style={{ display: showDetails.payment ? "flex" : "flex" }}
                  >
                    <label className="title">Payment Details</label>
                    <label>REF : {results?.transaction.paymentMPesaID}</label>
                    <label>
                      Amount : Ksh.
                      {results?.transaction.amount - results?.delivery_cost}
                    </label>
                    <label>
                      shipment fee : Ksh.{results?.delivery_cost ?? 0}
                    </label>
                    <label>Total : Ksh.{results?.transaction.amount}</label>
                    <label>Date : {results?.transaction.date}</label>
                  </Card>
                </>
              )}

              <Card
                className="payment"
                style={{ display: showDetails.items ? "flex" : "flex" }}
              >
                <label
                  className="title"
                  style={{ display: showDetails.items ? "flex" : "flex" }}
                >
                  Items{" "}
                </label>
                <div style={{
                  display:"flex",
                  gap:10,
                  flexDirection:"column",
                }}>
                {results?.data.map((item, index) => {
                  return (
                    <Link
                      to={`/products/${item.productName.split("/").join(".")}/${
                        item.productID
                      }`}
                      className="item"
                      key={item.productID}
                    >
                      <img
                        style={{
                          display: imageLoaded ? "flex" : "none",
                        }}
                        src={`${BASEURL}/${item.productImage}`}
                        onLoad={(e) => {
                          setImageLoaded(true);
                        }}
                        onError={(e) => {
                          setImageLoaded(false);
                        }}
                        onLoadStart={(e) => {}}
                        onLoadedData={(e) => {}}
                      />

                      <img
                        style={{
                          display: imageLoaded ? "none" : "flex",
                        }}
                        className="default"
                        src="/assets/images/savo_logo.jpg"
                      />
                      <div>
                        <label>
                          {item.productName.length > 40
                            ? `${item.productName.substring(0, 30)}...`
                            : item.productName}{" "}
                          x {item.saleOrderQuantity}
                        </label>
                        <label>Price : Ksh.{item.productPrice}</label>
                        <label>Total : {item.saleOrderTotal}</label>
                        <div
                          style={{
                            position: "absolute",
                            width: "50%",
                            bottom: 0,
                            right: 0,
                            display: results?.isDelivered ? "flex" : "none",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setProductIndex(index);
                            setModalShown(true);
                          }}
                        >
                          <CustomButton
                            title={"Rate"}
                            backgroundColor={"var(--app-color)"}
                          />
                        </div>
                      </div>
                    </Link>
                  );
                })}
                </div>
              </Card>

              {!results?.isDelivered && results?.deliveryMade ? (
                <CustomButton
                  title={received.loading ? "Loading..." : "Mark as received"}
                  backgroundColor={"var(--app-color)"}
                  pressAction={() => markAsReceived()}
                  disabled={received.loading ? true : false}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <BottomTabs />
      <Footer />
    </div>
  );
};

export default OrderDetails;
